export const environment = {
    name: 'test',
    production: false,
    baseUrl: 'https://test.almaphil.net',
    repoUrl: 'https://repository.test.almaphil.net',
    docUrl: 'https://almaphil.de/docs/',
    ssoUrl: 'https://sso.test.almaphil.de',
    sentry: {
        tracePropagationTargets: [
            'test.almaphil.de',
            'https://test.almaphil.net/api',
        ],
    },
};
